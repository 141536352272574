import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class Admission extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Admission"}
          titleDesc={
            "Have a hassle free admission process, from tracking online applications to sending students a final acceptance letter, with Fuse Classroom's Admission feature."
          }
          titleKeywords={
            "online admission system, admission systems, student admission management system, student admission system, centralized admission system, Online student admission system"
          }
          fbDes={"Implement a hassle free admission process with Fuse Classroom’s Admission feature."}
          twitDesc={"Implement a hassle free admission process with Fuse Classroom’s Admission feature."}
          seoLink={"platform/admission/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Admission</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Fuse Classroom’s admissions module greatly reduces the operational overhead for the admissions
                    process, from tracking applications to collecting payments and sending final acceptance letters, as
                    well as showcasing that your institution is embracing the latest technology, which will make the
                    best impression on prospective students.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Admission_Top_Request_Demo")}
                      state={{ page: "admission" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img src="/images/platform/admission/admission.webp" alt="Admission" width="640" height="auto" />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Admission */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="text-center title">Admission</div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#admission-form")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/admission-form.svg" alt="Admissions Form" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Admissions Form</Card.Title>
                      <div className="small">
                        Seamlessly track all online applications that your institution receives.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#digital-payment")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/digital-payment.svg" alt="Digital Payment" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Digital Payment</Card.Title>
                      <div className="small">Easily record and build reports on digital payments.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#eligibility-criteria-check")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/eligibility-criteria.svg"
                          alt="Eligibility Criteria Check"
                          className="img-fluid"
                        />
                      </div>
                      <Card.Title className="ai-title">Eligibility Criteria Check</Card.Title>
                      <div className="small">Quickly qualify candidates for admission to your institution.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#entrance-exam")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/entrance-exams.svg"
                          alt="Entrance Exams"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Entrance Exams</Card.Title>
                      <div className="small">
                        Entrance Exams can be scheduled and administered directly on the platform.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#final-acceptance")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/final-acceptance.svg" alt="Final Acceptance" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Final Acceptance</Card.Title>
                      <div className="small">
                        Send final acceptance letters and track student responses in one place.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Admission End */}

        {/* Admission Form */}
        <section id="admission-form" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/admission/admission-form.webp"
                  alt="Admission Form"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/admission-form.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Admission Form</h2>
                  <div className="h5 text-black font-weight-normal">
                    It’s never been easier for school administrators to track online applications to their institution.
                    Using this feature of Fuse Classroom you’ll be able at a glance view the whole list of applicants
                    and all relevant information about them.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Admission Form end */}

        {/* Digital Payment */}
        <section id="digital-payment" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/admission/digital-payment.webp"
                  alt="Digital Payment"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/digital-payment.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Digital Payment</h2>
                  <div className="h5 text-black font-weight-normal">
                    Digital payments are seamlessly tracked within the Fuse Classroom platform so that administrators
                    can easily view, export, and build reports on all payments that they receive from students in one
                    centralized place.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Digital Payment end */}

        {/* Eligibility Criteria Check */}
        <section id="eligibility-criteria-check" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/admission/eligibility-criteria.webp"
                  alt="Eligibility Criteria Check"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/eligibility-criteria.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Eligibility Criteria Check</h2>
                  <div className="h5 text-black font-weight-normal">
                    In our eligibility feature administrators can quickly qualify prospect candidates. This feature
                    highlights the student’s eligibility information, such as their academic history, health issues, and
                    other relevant details.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Eligibility Criteria Check end) */}

        {/* Entrance Exam */}
        <section className="py-5 bg-80 left" id="entrance-exam">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/admission/entrance-exam.webp"
                  alt="Entrance Exam"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/entrance-exams.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Entrance Exam</h2>
                  <div className="h5 text-black font-weight-normal">
                    The entrance exam feature allows administrators to schedule prospective students to take their
                    entrance exams either in-person or online. This feature includes an admin view that highlights when
                    students are scheduled to take their exams and which still need a timeslot.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Entrance Exam end */}

        {/* Classroom Discussions */}
        <section id="final-acceptance" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/admission/final-acceptance.webp"
                  alt="Final Acceptance"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/final-acceptance.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Final Acceptance</h2>
                  <div className="h5 text-black font-weight-normal">
                    With one click, institutions can send their final acceptance letters to all students who meet their
                    criteria for admittance. After sending the letters the platform will also track student responses
                    and pass on this information to the person who handles orientation.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Classroom Discussions end) */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("Admission_Bottom_Request_Demo")}
                    state={{ page: "Admission" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default Admission
